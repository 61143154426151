.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.logo {
  font-size: 24px;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.menu ul li {
  margin-right: 20px;
}

.profile {
  font-size: 20px;
}

.mobile-menu-toggle {
  display: none; /* Hide by default */
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .menu {
    display: none; /* Hide by default */
    width: 100%;
    text-align: center;
  }

  .menu.open {
    display: block; /* Show when menu is open */
  }

  .mobile-menu-toggle {
    display: block; /* Show toggle button */
    cursor: pointer;
    margin-top: 10px;
  }

  .mobile-menu-toggle span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: black;
    margin-bottom: 5px;
  }
}

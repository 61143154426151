.slide-bar {
    width: 100%;
    overflow: hidden;
  }
  
  .promotion-container {
    display: flex;
    flex-direction: row; /* Ensure promotions are displayed horizontally */
    animation: slide 20s linear infinite; /* Adjust duration as needed */
    gap: 5px; /* Adjust the gap between promotions */
  }
  
  @keyframes slide {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .promotion {
    flex-shrink: 0; /* Prevent promotions from shrinking */
    width: 200px; /* Set a fixed width for each promotion */
    height: auto; /* Let the height adjust according to content */
  }
  
  .promotion-heading {
    text-align: center;
    margin-bottom: 5px;
    font-size: 1.2rem; /* Adjust font size as needed */
  }
  
  .promotion-info {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.7);
    text-align: center; /* Center-align text */
  }
  
  .promotion h2 {
    margin-bottom: 5px;
    font-size: 1rem; /* Adjust font size as needed */
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
  }
  
  .promotion p {
    margin: 0;
    font-size: 0.8rem; /* Adjust font size as needed */
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis for overflow text */
  }
  
  .promotion-image-container {
    width: 100%; /* Ensure the container takes full width */
    overflow: hidden; /* Hide overflow */
  }
  
  .promotion-image {
    max-width: 100%;
    max-height: 150px; /* Adjust the maximum height as needed */
    display: block; /* Ensure the image does not have extra space below */
    margin: 0 auto; /* Center the image horizontally */
  }
  